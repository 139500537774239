import GATSBY_COMPILED_MDX from "/builds/axes4/online-pac/content/pages/privacy.de.mdx";
var _config$siteMetadata;
import React from 'react';
import {Layout} from './Layout';
import * as Stage from './Stage';
import {Section} from './Section/Section';
import {MDXProvider} from '@mdx-js/react';
import {LanguageVariantsProvider, useLanguageVariants} from '../../util/i18n';
import {Copy, Headings, Lists} from '../Text';
import {TargetAwareLink} from '../Link';
import TwoColumns from './TwoColumns';
import {Quote} from '../Quote';
import {ProductLogos} from '../Teaser';
import config from '../../../gatsby-config';
const configSiteUrl = (_config$siteMetadata = config.siteMetadata) === null || _config$siteMetadata === void 0 ? void 0 : _config$siteMetadata.siteUrl;
export const mdxComponents = {
  Section,
  h1: Headings.H1,
  h2: Headings.H2,
  h3: Headings.H3,
  h4: Headings.H4,
  ul: Lists.Ul,
  ol: Lists.Ol,
  a: TargetAwareLink,
  TwoColumns,
  blockquote: Quote,
  wrapper: Copy,
  ProductLogos
};
const LayoutWrapper = props => {
  const {setLanguageVariants} = useLanguageVariants();
  const languageVersions = props.data.languageVersions.edges.map(edge => ({
    language: edge.node.fields.language,
    path: configSiteUrl + "/" + edge.node.fields.language + "/" + edge.node.frontmatter.slug
  }));
  setLanguageVariants(languageVersions);
  return React.createElement(Layout, {
    title: props.data.mdx.frontmatter.title
  }, React.createElement(Stage.Small, {
    title: props.data.mdx.frontmatter.title
  }), React.createElement(MDXProvider, {
    components: mdxComponents
  }, props.children));
};
const MdxPage = props => {
  return React.createElement(LanguageVariantsProvider, null, React.createElement(LayoutWrapper, props));
};
const query = "4269890152";
MdxPage
export default function GatsbyMDXWrapper(props) {
  return React.createElement(MdxPage, props, React.createElement(GATSBY_COMPILED_MDX, props));
}
